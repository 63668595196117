import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

const Terms = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <article>
      <div className="c-content">
        <div className="container">
          <div className="row">
            <div className="c-content__content-container">
              <div className="row">
                <div className="c-content__title-container">
                  <h2 className="c-content__title">Terms and Conditions</h2>
                </div>
                <div className="c-content__summary-container cms-content">
                  <p>
                    Noah Media Group Limited (“Noah Media Group”) have taken every care in the preparation and publication of content in this web site.
                    To the extent permitted by applicable law, Noah Media Group disclaim all warranties, express or implied,
                    as to the accuracy of the information contained in any of the published material on this site.
                  </p>
                  <p>Noah Media Group shall not be liable to any person or organisation for any loss or damage, which may arise from the use of any information published on this site.</p>
                  <p>
                    Some hyperlinks contained in this site may lead to websites, which are not under the control of Noah Media Group.
                    Noah Media Group have no control over and will accept no responsibility or liability in respect of the material on any website,
                    which is not under the control of Noah Media Group Limited.
                  </p>
                  <p>
                    Copyright in these pages is owned by Noah Media Group except where otherwise indicated by a third party's own notice.
                    Images, trademarks and brands are also protected by other intellectual property laws and may not be reproduced or
                    appropriated in any way without written permission of their owners.
                  </p>
                  <p>The laws of England and Wales govern these conditions of use and you agree that the English courts shall have exclusive jurisdiction in any dispute.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default Terms
